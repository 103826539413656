<template>
  <div id="charter-tou">
    <b-container class="main-container">
      <b-row>
        <b-col>
          <h1>Charte des utilisateurs</h1>
        </b-col>
      </b-row>
      <b-row class="no-gutters">
        <b-col class="text-justify">
          <p>
            En tant que plateforme d'organisation de la recharge, notre objectif
            est de permettre aux conducteurs de véhicules électriques d'être
            plus serein dans leur mobilité en densifiant le réseau grâce aux
            accès à l'énergie déjà présents tout autour de nous. Cette
            révolution est possible grâce à la communauté d'EVENIR mise en place
            au travers notre application. Afin de préserver l'intégrité de notre
            communauté, il est primordial de respecter différentes règles de
            bonne conduite, aussi bien pour l'Hôte du service de recharge que
            pour l'Utilisateur.
          </p>
          <ol class="driver">
            <b>Conducteur</b>
            <li>Déclarer mon service de recharge</li>
            <li>Respecter les consignes d’accès et d’utilisation</li>
            <li>
              Laisser le point de recharge dans le même état que lorsque je l’ai
              trouvé
            </li>
            <li>
              Informer l'hôte de tout retard ou extension de mon temps de
              recharge
            </li>
            <li>
              Déclarer tout problème sur l’état du site ou du point de recharge
            </li>
            <li>Libérer le point de recharge à la fin du service</li>
            <li>
              Utiliser un véhicule et des câbles de recharge respectant les
              normes de sécurité
            </li>
            <li>
              Signaler toute utilisation frauduleuse du service de recharge
            </li>
            <li>
              Agir en toute bienveillance et respect de la communauté Evenir
            </li>
            <li>Adhérer et respecter les CGV-CGU du service Evenir</li>
          </ol>
          <ol class="provider">
            <b>Hôte</b>
            <li>Être propriétaire du point de recharge déclaré</li>
            <li>
              Donner toutes les informations d’accès et d’utilisation du point
              de recharge
            </li>
            <li>Proposer un point de recharge aux normes de sécurité</li>
            <li>Maintenir le point de recharge opérationnel</li>
            <li>
              Proposer un prix de service raisonnable afin de favoriser la
              fréquentation et la rotation
            </li>
            <li>Mettre à jour le planning de disponibilité</li>
            <li>Si l’accès est sécurisé, pensez à bien assurer une présence</li>
            <li>
              Signaler toute utilisation frauduleuse du service de recharge
            </li>
            <li>
              Agir en toute bienveillance et respect de la communauté Evenir
            </li>
            <li>Adhérer et respecter les CGV-CGU du service Evenir</li>
          </ol>
          <b-form-group>
            <b-form-checkbox type="checkbox" v-model="charterOk">
              J'ai lu et m'engage à respecter la charte des utilisateurs.
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox type="checkbox" v-model="touOk">
              J'ai lu et accepte les
              <a class="tou" v-b-modal.tou-modal @click.prevent>
                conditions générales d'utilisation</a
              >.
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="no-gutters">
        <b-col class="text-right">
          <b-button
            v-loader.disable="accepting"
            :disabled="!boxesChecked"
            variant="dark"
            @click="onAcceptTou"
          >
            Continuer</b-button
          >
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      id="tou-modal"
      title="CGU / Mentions Légales / Confidentialité"
      ok-title="J'ai bien lu"
      scrollable
      ok-only
      hide-header-close
      ok-variant="dark"
    >
      <b-tabs>
        <b-tab title="CGU">
          <TermsOfUse />
        </b-tab>
        <b-tab title="Mentions Légales">
          <LegalNotice />
        </b-tab>
        <b-tab title="Confidentialité">
          <PrivacyPolicy />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>
<script>
import TermsOfUse from '@/components/Legal/TermsOfUse.vue'
import LegalNotice from '@/components/Legal/LegalNotice.vue'
import PrivacyPolicy from '@/components/Legal/PrivacyPolicy.vue'
import { mapActions } from 'vuex'

export default {
  name: 'charter-tou',
  components: { TermsOfUse, LegalNotice, PrivacyPolicy },
  data() {
    return {
      charterOk: false,
      touOk: false,
      accepting: false
    }
  },
  computed: {
    boxesChecked() {
      return this.charterOk && this.touOk
    }
  },
  methods: {
    ...mapActions(['acceptTermsOfUse']),
    onAcceptTou() {
      this.accepting = true

      this.acceptTermsOfUse({ api: this.$apiService })
        .catch(err =>
          console.error('Uncaught error while accepting TOU: ', err)
        )
        .finally(() => (this.accepting = false))
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles.scss';

#charter-tou {
  margin-bottom: 20px;
  font-weight: $font-weight-regular;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;

  h1 {
    margin: 111px auto 9px;
    font-size: 13px;
    font-weight: $font-weight-bold;
    line-height: 17px;
    letter-spacing: 0.65px;
    text-transform: uppercase;
  }

  ol {
    margin-top: 30px;
    margin-left: 20px;
    padding-right: 10px;
    list-style-position: inside;

    & > * {
      position: relative;

      &::before {
        position: absolute;
        top: -20px;
        left: -61px;
        display: block;
        width: 50px;
        height: 100%;
        content: ' ';
      }

      &:first-child::before {
        height: 2em;
      }
    }

    &.driver {
      border: 1px solid $driver-blue;

      & > *::before {
        background: $driver-blue;
      }
    }

    &.provider {
      border: 1px solid $provider-yellow;

      & > *::before {
        background: $provider-yellow;
      }
    }
  }

  a.tou {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
@import '@/styles.scss';

#tou-modal {
  .modal-content {
    border-radius: 10px;

    .modal-header,
    .modal-footer {
      border: none;
    }

    .modal-header {
      h5 {
        font-family: $font-family;
        font-weight: $font-weight-regular;
        font-size: 16px;
        text-transform: uppercase;
      }
    }

    .modal-body {
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.7px;
      color: $text-light;

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: $base-text-color;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: $dark;
      }
    }

    .modal-footer {
      button {
        width: 100%;
      }
    }
  }
}
</style>
