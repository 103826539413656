var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(295)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { attrs: { align: "center" } }, [
      _c("b", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { attrs: { align: "center" } }, [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Conditions Générales d’Utilisation (« CGU »)")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { attrs: { align: "center" } }, [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("Avril 2020")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "La société EVENIR, SAS au capital de 30.000 euros immatriculée au Registre du Commerce et des Sociétés de Paris sous le n°830 800 488 et dont le siège social est situé 86, rue de l’Assomption - 75016 Paris, France (ci-après « EVENIR ») édite et exploite, en particulier via l’application « EVENIR » pour téléphone mobile, tablette et ordinateur et le site internet www.evenir.fr, une plateforme dénommée EVENIR permettant de mettre en relation des conducteurs de véhicules électriques (automobile et deux roues) avec des détenteurs de places de stationnement équipées de prises et/ou bornes de recharge pour véhicules électriques (ci-après « la Solution »)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les présentes Conditions Générales d’Utilisation ont pour objet de fixer les modalités et conditions générales par lesquelles EVENIR met la Solution à la disposition de l’Utilisateur et, d’autre part, l’Utilisateur peut accéder à la Solution et l’utiliser."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR est libre de modifier, à tout moment et sans préavis, les présentes CGU, en tout ou partie, afin notamment de prendre en compte toute évolution légale, jurisprudentielle, éditoriale et/ou technique ; l’Utilisateur sera informé de telles modifications lors de sa prochaine connexion à la Solution et/ou par e-mail, et sera invité à accepter la nouvelle version des CGU pour pouvoir continuer à l’utiliser."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 1. "),
          _c("u", [_vm._v("Définitions")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("Application : ")])
      ]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v(" l")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("’")])]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v(
            "application EVENIR, ses contenus, services et fonctionnalités, dans la version "
          )
        ])
      ]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("applicative pour téléphone mobile ")
        ])
      ]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("téléchargée")])
      ]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v(" et installée")])
      ]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v(" par ")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("l")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("’")])]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("Utilisateur")])
      ]),
      _c("span", [_c("span", { attrs: { lang: "FR" } })]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("ou consultée sur le site internet d")
        ])
      ]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("e ")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v(",")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } })]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("et ses éventuelles mises à jour ;")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Conducteur : toute personne physique ou morale utilisant la Solution aux fins d’accéder aux services d’un Hôte via l’Application ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Données personnelles : l’ensemble des données permettant d’identifier l’Utilisateur personne physique ou de le rendre identifiable, et notamment ses nom, prénoms, adresse email, numéro de téléphone, photographie, plaque d’immatriculation, carte d’identité, carte grise, et le cas échéant sa géolocalisation ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Hôte : toute personne physique ou morale utilisant la Solution en vue de donner accès à une place de stationnement et à un de temps de charge au profit d’un Conducteur équipé d’un Véhicule, moyennant le paiement d’un prix fixé par l’Hôte et d’une date convenue avec le Conducteur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Solution : la plateforme de mise en relation entre le Conducteur et l’Hôte conçue et rendue accessible par EVENIR via l’Application "
        )
      ]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("ou consultée sur le site internet d")
        ])
      ]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("’")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR,")])]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          " et l’ensemble des fonctionnalités y attachées, dont les modalités sont décrites sur l’Application et "
        )
      ]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("sur le site internet d")
        ])
      ]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("’")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR")])]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v(" ;")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Utilisateur : toute personne physique ou morale ayant téléchargé l’Application et créé un compte personnel en vue d’accéder à la Solution et d’utiliser tout ou partie de ses fonctionnalités en qualité de Conducteur et/ou d’Hôte ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Véhicule : tout véhicule automobile, deux roues ou tout autre engin de mobilité électrique ou hybride rechargeable ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 2. "),
          _c("u", [_vm._v("Portée des CGU")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les présentes CGU régissent la relation contractuelle entre EVENIR et l’Utilisateur."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 3. "),
          _c("u", [_vm._v("Installation de l’Application")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("L")])]),
      _c("span", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("’")])]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Application est disponible en téléchargement gratuit ")
        ])
      ]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("sur les plateformes de téléchargement ")
        ])
      ]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("pour les terminaux suivants :")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", { attrs: { lang: "FR" } }, [
        _vm._v("iPhone, à partir de la version 12 d’iOS")
      ]),
      _c("li", { attrs: { lang: "FR" } }, [
        _vm._v(
          " Smartphones fonctionnant sous Android, à partir de la version 9 (Pie) "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Application est également disponible gratuitement sur le site internet d’EVENIR."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _c("span", [
          _vm._v(
            "Les navigateurs suivants sont conseillés pour un usage optimal de l’application (version minimale) :"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", { attrs: { lang: "FR" } }, [_vm._v("Firefox 61")]),
      _c("li", { attrs: { lang: "FR" } }, [_vm._v("Edge 79")]),
      _c("li", { attrs: { lang: "FR" } }, [_vm._v("Chrome 50")]),
      _c("li", { attrs: { lang: "FR" } }, [_vm._v("Safari 11.1")]),
      _c("li", { attrs: { lang: "FR" } }, [_vm._v("Safari sur iOS 11.3")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _c("br"),
        _c("span", [
          _vm._v(
            "Des mises à jour et des options pourront ensuite être proposées "
          )
        ]),
        _c("span", [_vm._v("à l")]),
        _c("span", [_vm._v("’")]),
        _c("span", [_vm._v("Utilisateur")]),
        _c("span", [_vm._v(", à titre gratuit ou payant.")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 4. "),
          _c("u", [_vm._v("Modalités d’inscription")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("4.1. "),
        _c("u", [_vm._v("Inscription de l’Utilisateur")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("L’inscription à EVENIR est gratuite.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’accès à la Solution nécessite le téléchargement de l’Application. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("Lors de la première connexion, l’Utilisateur doit :")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("-"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("choisir la rubrique « "),
        _c("i", [_vm._v("S’inscrire")]),
        _vm._v(
          " » pour créer un compte personnel, en fournissant une adresse email. Cette adresse email est vérifiée par l’envoi instantané d’un code de vérification à récupérer dans la boite de messagerie correspondante, à renseigner dans l’Application sur la ligne « "
        ),
        _c("i", [_vm._v("Code de vérification")]),
        _vm._v(" » et à confirmer en cliquant sur « "),
        _c("i", [_vm._v("Vérifier le code")]),
        _vm._v(" » ; ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("-"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "créer un identifiant et un mot de passe personnels, que l’Utilisateur doit confirmer ; cet identifiant et ce mot de passe sont personnels et incessibles. Le mot de passe est composé d’au moins 8 caractères dont un chiffre, un caractère spécial et une majuscule ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("-"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "indiquer le nom et le pseudonyme qu’il souhaite voir s’afficher dans l’Application et, le cas échéant, insérer une photographie ; "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("-"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("cliquer sur « "),
        _c("i", [_vm._v("Créer")]),
        _vm._v(" » pour finaliser la création de son compte ;")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("-"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "accepter (opt-in) les CGU, la Charte EVENIR et la Politique de confidentialité."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur peut, à tout moment, choisir de modifier les informations le concernant par l’intermédiaire de la rubrique « "
        ),
        _c("i", [_vm._v("Mon compte")]),
        _vm._v(" » accessible depuis l’Application. ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Pour la modification de son mot de passe, l’Utilisateur doit revenir à la page de connexion et cliquer sur « "
        ),
        _c("i", [_vm._v("mot de passe oublié")]),
        _vm._v(" ». ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Toute connexion ou action réalisée à partir de l’identifiant et du mot de passe de l’Utilisateur sera réputée avoir été effectuée par ce dernier et l’obligera contractuellement, que ce soit à l’égard de EVENIR ou d’un autre Utilisateur. Toute perte, détournement ou utilisation non autorisée de l’identifiant et/ou du mot de passe et leurs conséquences relèvent de la responsabilité exclusive de l’Utilisateur. L’Utilisateur doit informer sans délai EVENIR de tout usage frauduleux de son compte en écrivant à : contact@evenir.energy"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("4.2. "),
        _c("u", [_vm._v("Inscription Conducteur")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Pour accéder pleinement à la Solution en qualité de Conducteur, et en particulier accéder aux propositions de bornes, le Conducteur doit, dans la rubrique « "
        ),
        _c("i", [_vm._v("Mon compte")]),
        _vm._v(
          " », identifier son Véhicule (type, numéro d’immatriculation, copie de carte grise) et renseigner son moyen de paiement via la solution STRIPE, dont le Conducteur doit accepter les conditions générales d’utilisation ["
        ),
        _c("span", [
          _c("span", [
            _c(
              "ins",
              {
                attrs: {
                  cite: "mailto:Olivier%20Marguillard",
                  datetime: "2020-04-15T16:31"
                }
              },
              [_vm._v("https://stripe.com/en-fr/legal#translation ")]
            )
          ]),
          _c("span", { staticClass: "msoDel" }, [
            _c(
              "del",
              {
                attrs: {
                  cite: "mailto:Olivier%20Marguillard",
                  datetime: "2020-04-15T16:31"
                }
              },
              [_vm._v("insérer un lien")]
            )
          ])
        ]),
        _vm._v("].")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur doit en outre transmettre enfin une copie de sa pièce identité (carte identité ou passeport) au format photo ou « .jpeg », et valider son inscription en qualité de Conducteur en cliquant sur « "
        ),
        _c("i", [_vm._v("Terminé")]),
        _vm._v(" ».")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR se réserve le droit de vérifier l’ensemble des informations fournies par le Conducteur et de refuser, provisoirement ou définitivement tout Conducteur n’ayant pas fourni à sa demande, à l’adresse email renseignée par ce dernier, les informations requises."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("4.3. "),
        _c("u", [_vm._v("Inscription Hôte")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Pour devenir Hôte, l’Utilisateur doit déclarer son point de charge dans la rubrique « "
        ),
        _c("i", [_vm._v("Mon compte")]),
        _vm._v(
          " » et fournir son IBAN pour obtenir le règlement des transactions via la solution STRIPE, dont l’Hôte doit accepter les conditions générales d’utilisation ["
        ),
        _c("span", [
          _c("span", { staticClass: "msoIns" }, [
            _c(
              "ins",
              {
                attrs: {
                  cite: "mailto:Olivier%20Marguillard",
                  datetime: "2020-04-15T16:32"
                }
              },
              [_vm._v("https://stripe.com/en-fr/legal#translation")]
            )
          ]),
          _c("span", { staticClass: "msoDel" }, [
            _c(
              "del",
              {
                attrs: {
                  cite: "mailto:Olivier%20Marguillard",
                  datetime: "2020-04-15T16:32"
                }
              },
              [_vm._v("insérer un lien")]
            )
          ])
        ]),
        _vm._v("]. ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte indique la localisation de son point de charge, sa puissance, s’il veut le rendre privé ou public. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte indique également le tarif horaire à la demi-heure, le type de stationnement et le type de démarrage de la borne (selon les listes aux choix multiples), et indique des détails complémentaires s’agissant des services par lui fournis aux Conducteurs."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte est invité à renseigner le planning de disponibilités, destiné à être rendu accessible aux Conducteurs."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte transmet une copie de sa pièce d’identité (photo ou « .jpeg ») et un justificatif de domicile de moins de 3 mois. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte finalise son inscription en cette qualité en cliquant sur « "
        ),
        _c("i", [_vm._v("Terminé")]),
        _vm._v(" ».")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR se réserve le droit de vérifier l’ensemble des informations fournies par l’Hôte et de refuser, provisoirement ou définitivement tout Hôte n’ayant pas fourni à sa demande, à l’adresse email renseignée par ce dernier, les informations requises."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 5. "),
          _c("u", [_vm._v("Modalités générales d’utilisation de la Solution")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "La Solution EVENIR met en relation les Conducteurs et les Hôtes selon les modalités suivantes :"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("(1)"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur définit l’objet de sa recherche depuis l’Application au moyen de plusieurs critères de recherche prédéfinis,"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("(2)"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le ou les Hôtes dont la localisation, l’équipement, les services et la disponibilité correspondent aux besoins du Conducteur au moment de la recherche du Conducteur sont affichés automatiquement et de façon aléatoire via le moteur de recherches EVENIR, "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("(3)"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur peut accéder au détail du service proposé par l’Hôte, sauf son adresse exacte, et choisit celui avec lequel il souhaite être mis en relation ; "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("(4)"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur paye en ligne, via STRIPE, la(les) prestation(s) de l’Hôte choisi ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("(5)"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("Le Conducteur ")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("reçoit via l’Application un message d")
      ]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("e confirmation ;")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("(6)"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("L’Hôte ")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("reçoit via l’Application une notification ;")
      ]),
      _c("span", { attrs: { lang: "FR" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("(7)"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte assure l’accès au point de charge et fournit le service convenu avec le Conducteur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("(8)"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte reçoit le règlement du Conducteur dans un délai de [_____] jours, sauf réclamation du Conducteur reçue par EVENIR dans ce délai."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 6. Modalités spécifiques Conducteur ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "La Solution fonctionne selon les modalités suivantes, que le Conducteur accepte expressément :"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("6.1. "),
        _c("u", [_vm._v("Sélection d’un point de charge")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur sélectionne, depuis l’Application, un point de charge parmi ceux identifiés comme disponibles pour le créneau horaire de son choix sur une carte de localisation."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les points de charge affichés sont classés selon trois couleurs reflétant leur disponibilité :"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("-"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "vert pour identifier les points de charge disponibles sur le créneau horaire"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("-"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "orange pour identifier les points de charge disponibles sur d’autres créneaux horaire"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("-"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("gris pour identifier les points de charge indisponibles.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Lorsque le Conducteur sélectionne un point de charge disponible, il peut consulter une fiche contenant le numéro de la borne, ses modalités d’accès et le prix du temps charge réservé à l’heure de son choix et procéder à la réservation du service."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Si la plage horaire sélectionnée initialement n’est pas disponible, la réservation se fait alors automatiquement sur la plage horaire effectivement disponible, laquelle est rappelée au Conducteur dans l’écran de confirmation qu’il doit valider avant de procéder au paiement."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("6.2. "),
        _c("u", [_vm._v("Paiement")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur doit régler l’Hôte via la solution de paiement sécurisé STRIPE. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur disposera d’une facture émise par EVENIR et transmise par email."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "La fourniture de la solution de paiement sécurisé STRIPE proposée par EVENIR aux Conducteurs est soumise à la réglementation française en matière de lutte contre le blanchiment et le financement du terrorisme. Le Conducteur est informé et accepte que les informations et données relatives à la transaction réalisée entre lui et un Hôte peuvent être transmises aux autorités compétentes, sous réserve de la réglementation applicable. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les frais bancaires éventuellement liés à la fourniture de la solution de paiement sécurisé STRIPE sont à la charge exclusive du Conducteur."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("6.3. "),
        _c("u", [_vm._v("Confirmation ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Une fois le paiement réalisé, le Conducteur reçoit un message de confirmation contenant "
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "l’adresse exacte de la place de stationnement mise à sa disposition par l’Hôte aux conditions convenues avec ce dernier."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 7. Modalités spécifiques Hôte")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("7.1. "),
        _c("u", [_vm._v("Fixation du Prix")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte fixe librement son prix de référence pour la fourniture au Conducteur d’un service de charge d’une durée 30 minutes. Ce prix ne peut être en dessous de 0,50€."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le montant facturé au Conducteur sera le temps passé en minute divisé par 30 minutes et multiplié par le prix de référence du service de charge, multiplié par la marge d’EVENIR. La durée minimale du service de charge est d’une (1) heure, puis par pas de quinze (15) minutes pour une durée maximale de trois (3) heures."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Hôte est informé et accepte que les informations et données relatives à la transaction et réalisée entre lui et un Conducteur peuvent être transmises aux autorités compétentes, sous réserve de la réglementation applicable."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("7.2. "),
        _c("u", [_vm._v("Redevance et frais de service au profit de EVENIR")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le prix final affiché auprès de l’Hôte et payé à ce dernier par le Conducteur via STRIPE est majoré, pour chaque transaction, d’une redevance de [20] % et d’une somme supplémentaire de"
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          " 0,20 euros TTC par transaction au titre des frais de service EVENIR (notamment frais de prélèvement)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("7.3. "),
        _c("u", [_vm._v("Modalités de règlement de l’Hôte")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les règlements des sommes revenant à l’Hôte (prix payé par les Conducteurs déduction faite des redevances et frais EVENIR prévus à l’article 7.2) sont réalisés par virement au plus tard dans les (X) jours suivant la période mensuelle considérée."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 8. "),
          _c("u", [_vm._v("Annulation - Droit de rétractation")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("8.1. "),
        _c("u", [_vm._v("Frais d’annulation")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("Le conducteur ne peut pas annuler sa prise de rendez-vous.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("8.2. "),
        _c("u", [_vm._v("Absence de droit de rétractation")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur ne bénéficie pas de droit de rétractation, en application des dispositions de l’article L. 221-28 du Code de la consommation, dans la mesure où il accepte expressément que la prestation soit pleinement exécutée avant le délai légal de 14 (quatorze) jours après consignation du prix. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Conducteur renonce expressément à son droit de rétractation lors de l’envoi de sa demande à un Hôte via l’Application. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 9. "),
          _c("u", [_vm._v("Obligations d’EVENIR")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR offre une plateforme purement technique de mise en relation permettant à ses Utilisateurs d’entrer en contact entre eux."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR n’est en aucun cas partie à la relation juridique ou contractuelle se formant entre le Conducteur et le ou les Hôtes de son choix, et entre les Utilisateurs et la solution de paiement STRIPE mise à leur disposition via l’Application. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR ne fournit aucun autre service que la mise en relation, sur la base des informations fournies par le Conducteur et par chaque Hôte, et ne garantit aucunement, que ce soit de manière directe ou indirecte, la véracité ou la légitimité des informations par eux fournis, s’agissant par exemple des modalités d’accès au point de charge, à son état ou à la puissance délivrée ; les Hôtes fournissent leurs services sous leur seule et entière responsabilité, s’agissant en particulier du respect de toute réglementation et/ou obligation administrative, fiscale, comptable ou contractuelle à leur charge. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "En cas de réclamation ou de litige entre le Conducteur et l’Hôte, EVENIR, qui pourra être informée par la partie la plus diligente, pourra intervenir en qualité d’amiable compositeur entre les parties mais ne pourra en aucun cas voir sa responsabilité être engagée ni déférer à l’une quelconque des demandes ou injonctions des parties ou de leurs conseils, sauf à appliquer une décision de justice devenue exécutoire."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR veillera à sécuriser l’accès et l’utilisation de la Solution, laquelle est en principe accessible 24 heures sur 24, 7 jours sur 7 sauf en cas de force majeure ou de survenance d’un évènement hors du contrôle de EVENIR et sous réserve des éventuelles pannes, aléas techniques, actes de malveillance, atteintes portées au matériel et interventions de maintenance ou de mises à jour nécessaires à son bon fonctionnement. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "La responsabilité d’EVENIR ne peut être mise en cause en cas de force majeure au sens de l’article 1218 du Code civil et de la jurisprudence subséquente, dans l’hypothèse de la survenance d’un événement empêchant l’utilisation de l’Application, de la Solution et/ou de ses services, en tout ou partie. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR offre aux Utilisateurs un service d’information et de support en ligne accessible depuis l’Application."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 10. "),
          _c("u", [_vm._v("Obligations de l’Utilisateur")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur s’engage à respecter les CGU et la Charte EVENIR."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "De manière générale, et sans que cette liste puisse être considérée comme exhaustive, l’Utilisateur s’interdit de :"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "fournir volontairement des informations incomplètes, erronées ou inexactes, ou dont il n’est pas titulaire ou détenteur légitime ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "utiliser la Solution à des fins commerciales et/ou non privées, ou dans des conditions ou selon des modalités qui entraîneraient la violation par lui d’autres dispositions ou engagements contractuels ou constitutives d’une fraude ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "télécharger, reproduire, enregistrer, copier, compiler, décompiler, partager avec un tiers ou exploiter, par tout procédé ou moyen, l’Application et/ou le contenu de la Solution et les résultats de son utilisation ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("entraver ou perturber le fonctionnement de la Solution;")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "tenter d’induire en erreur d’autres Utilisateurs ou des tiers, en usurpant le nom ou la dénomination sociale d’autres personnes, ou en se faisant passer pour un employé ou un partenaire de EVENIR, ou pour un modérateur ou un hébergeur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "déposer, diffuser ou utiliser sur ou depuis la Solution des contenus portant atteinte aux droits de tiers, et en particulier à des droits de propriété intellectuelle (marque, droit d’auteur, modèles en particulier), aux droits de personne (notamment usurpation d’identité, diffamation, insultes, injures, dénigrement, harcèlement, etc.), au respect de la vie privée (en ce compris le droit à l’image), à l’ordre public et aux bonnes mœurs (notamment apologie des crimes contre l’humanité, incitation à la haine raciale, atteinte à la dignité humaine, etc.) et, plus généralement, à la réglementation applicable en vigueur et aux règles habituelles de politesse et de courtoisie."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Chaque Utilisateur garantit EVENIR contre toute réclamation, action et/ou revendication d’autres Utilisateurs ou de tiers concernant son utilisation de la Solution."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 11. "),
          _c("u", [_vm._v("Protection des Données personnelles")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR est le responsable du traitement des Données personnelles réalisé via la Solution et, en cette qualité, s’engage à respecter la loi n°78-17 du 6 janvier 1978 (dite loi « Informatique et Libertés ») modifiée et le Règlement général sur la protection des données (dit « RGPD »)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les Données personnelles communiquées par l’Utilisateur à EVENIR font l’objet d’un traitement informatique et sont conservées et utilisées par EVENIR pendant toute la durée de l’inscription de l’Utilisateur à la Solution et une durée supplémentaire de [___], pour les finalités suivantes :"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "permettre l’accès à la Solution et son utilisation par l’Utilisateur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "vérifier et utiliser les données et éléments d’identification fournis et permettre leur utilisation via la Solution pour accéder aux informations sollicitées par l’Utilisateur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "partager et/ou renseigner les données d’identification et les demandes de l’Utilisateur avec pour assurer la prise de rendez-vous et la réalisation des prestations de charge aux dates et horaires convenus ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "assurer le fonctionnement de la Solution et de ses services, et notamment la facturation ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "améliorer les différents services proposés par la Solution et fournir à l’Utilisateur des informations pertinentes et à jour, répondre aux questions de l’Utilisateur ou à ses demandes en cas d’assistance, "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "procéder à des statistiques sur la base d’informations ou de données non nominatives et utiliser ces statistiques pour améliorer la Solution et ses services ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "envoyer à l’Utilisateur des notifications et, avec son accord préalable exprès, des newsletters et des messages d’informations ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "envoyer à l’Utilisateur des notifications afin de vérifier la sécurité des accès à la Solution et aux informations ou données transmises à la requête de l’Utilisateur ou à son profit."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Par ailleurs, lorsque l’Utilisateur se connecte à la Solution, des données de navigation et de géolocalisation peuvent être collectées et traitées par EVENIR ou tout prestataire de son choix à des fins statistiques afin d’améliorer l’ergonomie de l’Application, les fonctionnalités de la Solution et de mesurer le nombre de pages vues, le nombre de visites, ainsi que l’activité et le parcours de l’Utilisateur. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR prend toutes les mesures adéquates sur le plan technique et organisationnel pour assurer la sécurité des données personnelles qui lui sont confiées et, en particulier, interdire tout accès non autorisé et/ou divulgation. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les Données personnelles sont stockées sur des serveurs sécurisés hébergés via la solution Microsoft Azure."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR invite l’Utilisateur à la plus grande prudence dans la communication de ses données personnelles à des tiers, et en particulier à ne pas répondre à des sollicitations ou messages (email, SMS…) présentés sous les marques et logos EVENIR qui seraient reçus en dehors de l’Application. De tels messages ou sollicitations peuvent provenir de personnes mal intentionnées cherchant à obtenir des informations personnelles vous concernant en vue d’une utilisation frauduleuse (pratique du phishing). Si vous recevez un message qui paraît être une tentative de phishing, ne répondez pas et n’ouvrez pas les pièces jointes, les images ou les liens contenus dans le message. Signalez-le à : [email] et sur "
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _c("a", { attrs: { href: "http://www.signal-spam.fr" } }, [
          _c("span", [_vm._v("www.signal-spam.fr")])
        ])
      ]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v(".")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les données de l’Utilisateur pourront être communiquées à des tiers sous-traitants d’EVENIR [situés en France ou dans l’Union Européenne] pour assurer l’accès et l’utilisation de la Solution et de ses fonctionnalités et services."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les Données personnelles de l’Utilisateur ne font pas l’objet de communication ou de transfert à des tiers à des fins commerciales ou promotionnelles, sauf accord préalable et exprès de l’Utilisateur."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le choix par l’Utilisateur de recevoir des offres ou messages de prospection commerciale de la part d’EVENIR et/ou de ses partenaires est subordonné à son accord préalable et exprès au moyen d’une case à cocher correspondante accessible lors de son inscription. Si l’Utilisateur ne souhaite plus les recevoir, il pourra se désinscrire à tout moment via le lien situé au bas de chacun des messages reçus."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les Données personnelles de l’Utilisateur ne seront pas conservées au-delà de la durée d’inscription de l’Utilisateur à la Solution pour les finalités visées ci-avant."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur peut, à tout moment, accéder à son compte pour modifier ou supprimer les informations et données le concernant. Les informations seront alors définitivement supprimées de la base de données active et mise en archivage sécurisé pour permettre à EVENIR de répondre à ses obligations légales (comptabilité, réquisition judiciaire…) pour une durée de 2 (deux) années."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les comptes inactifs des Utilisateurs ainsi que l’ensemble des Données personnelles s’y rapportant seront archivés dans un délai de 18 mois à compter du jour de dernière connexion de l’Utilisateur puis les données archivées seront supprimées à défaut de nouvelle connexion passé un délai de 18 mois."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Conformément à la loi « Informatique et Libertés » et au RGPD, l’Utilisateur personne physique dispose d’un droit d’information, d’accès, d’opposition, de rectification, d’effacement et de suppression de tout ou partie des Données personnelles le concernant, et d’un droit à la limitation du traitement et à la portabilité des Données personnelles, qu’il peut exercer en écrivant à : [email] ou [adresse]."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "De telles demandes devront être présentées par l’Utilisateur personnellement et par écrit, et, à la demande de EVENIR ou de son DPO, signées et accompagnées de la photocopie d’un titre d’identité portant la signature de son titulaire. Ces informations ne seront communiquées qu’à des fins d’identification de l’Utilisateur et ne seront pas conservées pour une durée excédant celle nécessaire au traitement de la réponse. La demande précisera l’adresse email ou postale à laquelle devra parvenir l’éventuelle réponse. EVENIR disposera d’un délai de 2 (deux) mois pour répondre suivant réception de la demande."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur dispose également du droit de définir des directives générales relatives à la conservation, à l’effacement et à la communication de ses Données personnelles après son décès, qui peuvent être enregistrées auprès d’un tiers de confiance numérique certifié par la CNIL (www.cnil.fr), ainsi que des directives particulières relatives à la Solution et pouvant être enregistrées auprès de EVENIR, à l’adresse susmentionnée."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur dispose par ailleurs du droit de formuler une réclamation auprès des autorités, et en particulier de la CNIL (www.cnil.fr)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR informe l’Utilisateur que, conformément à la législation en vigueur, les données fournies par ce dernier pourront être communiquées à toute autorité publique ou judiciaire qui lui en ferait la demande."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 12. "),
          _c("u", [_vm._v("Propriété intellectuelle")])
        ])
      ]),
      _c("span", { attrs: { lang: "FR" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR (en ce compris l’Application) constitue une œuvre protégée par la législation en vigueur, et notamment les articles L. 122-1 et suivants du Code de la propriété intellectuelle, exploitée par la société EVENIR. Les textes, photographies, images animées ou non, savoir-faire, dessins, graphismes et tout autre élément composant la Solution sont la propriété de EVENIR ou de tiers ayant autorisé EVENIR à les utiliser. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Toute reproduction intégrale ou partielle de la Solution est interdite, sauf accord préalable exprès et écrit d’EVENIR."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Toute utilisation contraire de tout ou partie de la Solution ou de l’un ou quelconque de ses éléments constituerait une contrefaçon telle que définie aux articles L. 335- 2 et suivants du code de la propriété intellectuelle, et est susceptible d’entraîner des poursuites civiles et/ou pénales. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR se réserve le droit de refuser l’utilisation de sa marque ou de son logo par un tiers, sans son accord préalable exprès. Toute reproduction ou utilisation de sa marque ou de son logo sans autorisation préalable est constitutive de contrefaçon et entraînera des poursuites civiles ou pénales."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR concède uniquement à l’Utilisateur, à titre personnel, non exclusif et non transférable, un droit d’utilisation temporaire des éléments susvisés, afin de permettre l’utilisation de la Solution et l’exécution des présentes CGU. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Ce droit d’utilisation prend fin à l’issue des relations contractuelles entre EVENIR et l’Utilisateur, pour quelque cause que ce soit. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Toute reproduction autorisée par EVENIR (dans un but personnel, associatif ou professionnel) implique l’indication de la source (EVENIR) et un lien vers la Solution. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 13. "),
          _c("u", [_vm._v("Durée – Résiliation")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("Les CGU sont conclues pour une durée indéterminée. ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur peut suspendre ou clôturer son compte personnel à tout moment, pour quelque raison que ce soit, en adressant une demande écrite par email à : contact@evenir.energy"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR peut également mettre un terme à l’usage par l’Utilisateur de la Solution et par conséquent résilier les CGU, en cas de non-respect ou de violation desdites CGU par l’Utilisateur. La résiliation prendra effet moyennant un préavis raisonnable, après information et échange avec l’Utilisateur."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 14. "),
          _c("u", [
            _vm._v("Absence de renonciation tacite – Indépendance des clauses")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le fait que EVENIR n’ait pas exigé l’application d’une clause quelconque des CGU ne pourra en aucun cas être considéré comme sa renonciation aux droits découlant de ladite clause, ni comme une renonciation à faire valoir l’application d’une autre clause des présentes."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Dans l’hypothèse où l’une quelconque des clauses des CGU serait déclarée nulle, réputée non écrite ou invalide par une décision judiciaire définitive, cette clause serait réputée détachable des autres clauses des CGU et n’affecterait en aucun cas leur validité."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 15. "),
          _c("u", [_vm._v("Litige - Médiation")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "En cas de litige, l’Utilisateur est invité à contacter EVENIR sur l’Application dans la rubrique « "
        ),
        _c("i", [_vm._v("Mes réclamations")]),
        _vm._v(" » ou par email : contact@evenir.energy")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { name: "_Hlk26802512" } }, [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v(
            "Si le litige relatif à l’interprétation ou à l’exécution des présentes CGU entre l’Utilisateur et EVENIR n’a pu être résolu amiablement entre les parties, EVENIR met à la disposition de l’Utilisateur, agissant en qualité de consommateur, conformément aux dispositions du Code de la consommation, un dispositif de médiation en vue de la résolution amiable du litige ; l’Utilisateur peut recourir gratuitement au médiateur de la consommation de l’Association des Médiateurs Européens dont les coordonnées sont les suivantes : 197, Boulevard Saint-Germain, 75007 PARIS. Les modalités de saisine du Médiateur sont accessibles sur le lien suivant : http://www.mediationconso-ame.com/"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Article 16. "),
          _c("u", [_vm._v("Droit applicable - Juridiction")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("Les présentes CGU seront régies par le droit français.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Tout litige entre EVENIR et l’Utilisateur devra faire l’objet d’une tentative de résolution amiable. A défaut d’issue amiable dans le délai de 60 (soixante) jours à compter de la survenance du litige, celui-ci, quelle qu’en soit l’origine, qu’il s’agisse notamment de la formation, de l’exécution, de l’interprétation, de la validité, de la résiliation ou de la résolution des présentes CGU et ce y compris pour les procédures conservatoires, les procédures d’urgence, en cas de référé, d’appel en garantie, de requête ou de pluralité de défendeurs, sera de la compétence des juridictions françaises."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _vm._m(5),
      _vm._m(6),
      _vm._m(7),
      _vm._m(8),
      _vm._m(9),
      _vm._m(10),
      _vm._m(11),
      _vm._m(12),
      _vm._m(13),
      _vm._m(14),
      _vm._m(15),
      _vm._m(16),
      _vm._m(17),
      _vm._m(18),
      _vm._m(19),
      _vm._m(20),
      _vm._m(21),
      _vm._m(22),
      _vm._m(23),
      _vm._m(24),
      _vm._m(25),
      _vm._m(26),
      _vm._m(27),
      _vm._m(28),
      _vm._m(29),
      _vm._m(30),
      _vm._m(31),
      _vm._m(32),
      _vm._m(33),
      _vm._m(34),
      _vm._m(35),
      _vm._m(36),
      _vm._m(37),
      _vm._m(38),
      _vm._m(39),
      _vm._m(40),
      _vm._m(41),
      _vm._m(42),
      _vm._m(43),
      _vm._m(44),
      _vm._m(45),
      _vm._m(46),
      _vm._m(47),
      _vm._m(48),
      _vm._m(49),
      _vm._m(50),
      _vm._m(51),
      _vm._m(52),
      _vm._m(53),
      _vm._m(54),
      _vm._m(55),
      _vm._m(56),
      _vm._m(57),
      _vm._m(58),
      _vm._m(59),
      _vm._m(60),
      _vm._m(61),
      _vm._m(62),
      _vm._m(63),
      _vm._m(64),
      _vm._m(65),
      _vm._m(66),
      _vm._m(67),
      _vm._m(68),
      _vm._m(69),
      _vm._m(70),
      _vm._m(71),
      _vm._m(72),
      _vm._m(73),
      _vm._m(74),
      _vm._m(75),
      _vm._m(76),
      _vm._m(77),
      _vm._m(78),
      _vm._m(79),
      _vm._m(80),
      _vm._m(81),
      _vm._m(82),
      _vm._m(83),
      _vm._m(84),
      _vm._m(85),
      _vm._m(86),
      _vm._m(87),
      _vm._m(88),
      _vm._m(89),
      _vm._m(90),
      _vm._m(91),
      _vm._m(92),
      _vm._m(93),
      _vm._m(94),
      _vm._m(95),
      _vm._m(96),
      _vm._m(97),
      _vm._m(98),
      _vm._m(99),
      _vm._m(100),
      _vm._m(101),
      _vm._m(102),
      _vm._m(103),
      _vm._m(104),
      _vm._m(105),
      _vm._m(106),
      _vm._m(107),
      _vm._m(108),
      _vm._m(109),
      _vm._m(110),
      _vm._m(111),
      _vm._m(112),
      _vm._m(113),
      _vm._m(114),
      _vm._m(115),
      _vm._m(116),
      _vm._m(117),
      _vm._m(118),
      _vm._m(119),
      _vm._m(120),
      _vm._m(121),
      _vm._m(122),
      _vm._m(123),
      _vm._m(124),
      _vm._m(125),
      _vm._m(126),
      _vm._m(127),
      _vm._m(128),
      _vm._m(129),
      _vm._m(130),
      _vm._m(131),
      _vm._m(132),
      _vm._m(133),
      _vm._m(134),
      _vm._m(135),
      _vm._m(136),
      _vm._m(137),
      _vm._m(138),
      _vm._m(139),
      _vm._m(140),
      _vm._m(141),
      _vm._m(142),
      _vm._m(143),
      _vm._m(144),
      _vm._m(145),
      _vm._m(146),
      _vm._m(147),
      _vm._m(148),
      _vm._m(149),
      _vm._m(150),
      _vm._m(151),
      _vm._m(152),
      _vm._m(153),
      _vm._m(154),
      _vm._m(155),
      _vm._m(156),
      _vm._m(157),
      _vm._m(158),
      _vm._m(159),
      _vm._m(160),
      _vm._m(161),
      _vm._m(162),
      _vm._m(163),
      _vm._m(164),
      _vm._m(165),
      _vm._m(166),
      _vm._m(167),
      _vm._m(168),
      _vm._m(169),
      _vm._m(170),
      _vm._m(171),
      _vm._m(172),
      _vm._m(173),
      _vm._m(174),
      _vm._m(175),
      _vm._m(176),
      _vm._m(177),
      _vm._m(178),
      _vm._m(179),
      _vm._m(180),
      _vm._m(181),
      _vm._m(182),
      _vm._m(183),
      _vm._m(184),
      _vm._m(185),
      _vm._m(186),
      _vm._m(187),
      _vm._m(188),
      _vm._m(189),
      _vm._m(190),
      _vm._m(191),
      _vm._m(192),
      _vm._m(193),
      _vm._m(194),
      _vm._m(195),
      _vm._m(196),
      _vm._m(197),
      _vm._m(198),
      _vm._m(199),
      _vm._m(200),
      _vm._m(201),
      _vm._m(202),
      _vm._m(203),
      _vm._m(204),
      _vm._m(205),
      _vm._m(206),
      _vm._m(207),
      _vm._m(208),
      _vm._m(209),
      _vm._m(210),
      _vm._m(211),
      _vm._m(212),
      _vm._m(213),
      _vm._m(214),
      _vm._m(215),
      _vm._m(216),
      _vm._m(217),
      _vm._m(218),
      _vm._m(219),
      _vm._m(220),
      _vm._m(221),
      _vm._m(222),
      _vm._m(223),
      _vm._m(224),
      _vm._m(225),
      _vm._m(226),
      _vm._m(227),
      _vm._m(228),
      _vm._m(229),
      _vm._m(230),
      _vm._m(231),
      _vm._m(232),
      _vm._m(233),
      _vm._m(234),
      _vm._m(235),
      _vm._m(236),
      _vm._m(237),
      _vm._m(238),
      _vm._m(239),
      _vm._m(240),
      _vm._m(241),
      _vm._m(242),
      _vm._m(243),
      _vm._m(244),
      _vm._m(245),
      _vm._m(246),
      _vm._m(247),
      _vm._m(248),
      _vm._m(249),
      _vm._m(250),
      _vm._m(251),
      _vm._m(252),
      _vm._m(253),
      _vm._m(254),
      _vm._m(255),
      _vm._m(256),
      _vm._m(257),
      _vm._m(258),
      _vm._m(259),
      _vm._m(260),
      _vm._m(261),
      _vm._m(262),
      _vm._m(263),
      _vm._m(264),
      _vm._m(265),
      _vm._m(266),
      _vm._m(267),
      _vm._m(268),
      _vm._m(269),
      _vm._m(270),
      _vm._m(271),
      _vm._m(272),
      _vm._m(273),
      _vm._m(274),
      _vm._m(275),
      _vm._m(276),
      _vm._m(277),
      _vm._m(278),
      _vm._m(279),
      _vm._m(280),
      _vm._m(281),
      _vm._m(282),
      _vm._m(283),
      _vm._m(284),
      _vm._m(285),
      _vm._m(286),
      _vm._m(287),
      _vm._m(288),
      _vm._m(289),
      _vm._m(290),
      _vm._m(291),
      _vm._m(292),
      _vm._m(293),
      _vm._m(294)
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }