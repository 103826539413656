var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "charter-tou" } },
    [
      _c(
        "b-container",
        { staticClass: "main-container" },
        [
          _c(
            "b-row",
            [_c("b-col", [_c("h1", [_vm._v("Charte des utilisateurs")])])],
            1
          ),
          _c(
            "b-row",
            { staticClass: "no-gutters" },
            [
              _c(
                "b-col",
                { staticClass: "text-justify" },
                [
                  _c("p", [
                    _vm._v(
                      " En tant que plateforme d'organisation de la recharge, notre objectif est de permettre aux conducteurs de véhicules électriques d'être plus serein dans leur mobilité en densifiant le réseau grâce aux accès à l'énergie déjà présents tout autour de nous. Cette révolution est possible grâce à la communauté d'EVENIR mise en place au travers notre application. Afin de préserver l'intégrité de notre communauté, il est primordial de respecter différentes règles de bonne conduite, aussi bien pour l'Hôte du service de recharge que pour l'Utilisateur. "
                    )
                  ]),
                  _c("ol", { staticClass: "driver" }, [
                    _c("b", [_vm._v("Conducteur")]),
                    _c("li", [_vm._v("Déclarer mon service de recharge")]),
                    _c("li", [
                      _vm._v("Respecter les consignes d’accès et d’utilisation")
                    ]),
                    _c("li", [
                      _vm._v(
                        " Laisser le point de recharge dans le même état que lorsque je l’ai trouvé "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        " Informer l'hôte de tout retard ou extension de mon temps de recharge "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        " Déclarer tout problème sur l’état du site ou du point de recharge "
                      )
                    ]),
                    _c("li", [
                      _vm._v("Libérer le point de recharge à la fin du service")
                    ]),
                    _c("li", [
                      _vm._v(
                        " Utiliser un véhicule et des câbles de recharge respectant les normes de sécurité "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        " Signaler toute utilisation frauduleuse du service de recharge "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        " Agir en toute bienveillance et respect de la communauté Evenir "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Adhérer et respecter les CGV-CGU du service Evenir"
                      )
                    ])
                  ]),
                  _c("ol", { staticClass: "provider" }, [
                    _c("b", [_vm._v("Hôte")]),
                    _c("li", [
                      _vm._v("Être propriétaire du point de recharge déclaré")
                    ]),
                    _c("li", [
                      _vm._v(
                        " Donner toutes les informations d’accès et d’utilisation du point de recharge "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Proposer un point de recharge aux normes de sécurité"
                      )
                    ]),
                    _c("li", [
                      _vm._v("Maintenir le point de recharge opérationnel")
                    ]),
                    _c("li", [
                      _vm._v(
                        " Proposer un prix de service raisonnable afin de favoriser la fréquentation et la rotation "
                      )
                    ]),
                    _c("li", [
                      _vm._v("Mettre à jour le planning de disponibilité")
                    ]),
                    _c("li", [
                      _vm._v(
                        "Si l’accès est sécurisé, pensez à bien assurer une présence"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        " Signaler toute utilisation frauduleuse du service de recharge "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        " Agir en toute bienveillance et respect de la communauté Evenir "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Adhérer et respecter les CGV-CGU du service Evenir"
                      )
                    ])
                  ]),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { type: "checkbox" },
                          model: {
                            value: _vm.charterOk,
                            callback: function($$v) {
                              _vm.charterOk = $$v
                            },
                            expression: "charterOk"
                          }
                        },
                        [
                          _vm._v(
                            " J'ai lu et m'engage à respecter la charte des utilisateurs. "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { type: "checkbox" },
                          model: {
                            value: _vm.touOk,
                            callback: function($$v) {
                              _vm.touOk = $$v
                            },
                            expression: "touOk"
                          }
                        },
                        [
                          _vm._v(" J'ai lu et accepte les "),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.tou-modal",
                                  modifiers: { "tou-modal": true }
                                }
                              ],
                              staticClass: "tou",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                }
                              }
                            },
                            [_vm._v(" conditions générales d'utilisation")]
                          ),
                          _vm._v(". ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "no-gutters" },
            [
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "loader",
                          rawName: "v-loader.disable",
                          value: _vm.accepting,
                          expression: "accepting",
                          modifiers: { disable: true }
                        }
                      ],
                      attrs: { disabled: !_vm.boxesChecked, variant: "dark" },
                      on: { click: _vm.onAcceptTou }
                    },
                    [_vm._v(" Continuer")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tou-modal",
            title: "CGU / Mentions Légales / Confidentialité",
            "ok-title": "J'ai bien lu",
            scrollable: "",
            "ok-only": "",
            "hide-header-close": "",
            "ok-variant": "dark"
          }
        },
        [
          _c(
            "b-tabs",
            [
              _c("b-tab", { attrs: { title: "CGU" } }, [_c("TermsOfUse")], 1),
              _c(
                "b-tab",
                { attrs: { title: "Mentions Légales" } },
                [_c("LegalNotice")],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Confidentialité" } },
                [_c("PrivacyPolicy")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }