var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(174)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { attrs: { align: "center" } }, [
        _c("b", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR")])])
      ]),
      _c("p", { attrs: { align: "center" } }, [
        _c("b", [
          _c("span", { attrs: { lang: "FR" } }, [
            _vm._v("POLITIQUE DE CONFIDENTIALITE")
          ])
        ])
      ]),
      _c("p", { attrs: { align: "center" } }, [
        _c("b", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("Avril 2020")])])
      ]),
      _c("p", [_c("span", { attrs: { lang: "FR" } })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "La présente Politique de confidentialité régit la collecte et le traitement par la société "
        ),
        _c("span", { staticStyle: { color: "black" } }, [_vm._v("EVENIR ")]),
        _vm._v(
          "des données à caractère personnel (ci-après les « Données Personnelles ») des utilisateurs et clients (ci-après les « Utilisateurs ») du site internet "
        ),
        _c("a", { attrs: { href: "http://www.evenir.energy" } }, [
          _vm._v("www.evenir.energy")
        ]),
        _c("span", [
          _vm._v(" et/ou de l’application pour appareils mobiles EVENIR ")
        ]),
        _vm._v("(ci-après séparément ou ensemble le « Site »). ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("u", [_c("span", { attrs: { lang: "FR" } }, [_c("span")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { name: "_gjdgxs" } }),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "On entend par Données Personnelles les informations et données permettant d’identifier ou rendant identifiable une personne physique, et entre autres informations, son nom, son adresse email, son numéro de téléphone ou son adresse IP."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Cette Politique de confidentialité est applicable à raison de toute utilisation du Site et de ses différents services et fonctionnalités."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "La présente Politique de confidentialité complète les dispositions des Conditions Générales d’Utilisation accessibles "
        ),
        _c(
          "a",
          { attrs: { href: "https://app.evenir.energy/public/docs/cgu.pdf" } },
          [_vm._v("ici")]
        ),
        _vm._v(".")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("1."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("RESPONSABLE DE TRAITEMENT")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("La société ")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR, SAS au capital de 30.000 euros immatriculée au Registre du Commerce et des Sociétés de Paris sous le n°830 800 488 et dont le siège social est situé 86, rue de l’Assomption - 75016 Paris, France,"
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          " est le Responsable du traitement des Données Personnelles réalisé dans le cadre de l’édition et de l’exploitation du Site."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          " s’engage à respecter les dispositions issues de la Loi n°78-17 du 6 janvier 1978 (dite « Informatique et Libertés ») dans sa version modifiée et celles du Règlement Général sur la Protection des Données Personnelles (dit « RGPD »)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le Site est hébergé via la solution Microsoft Azure dans des conditions conformes au RGPD."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticStyle: { color: "black" }, attrs: { lang: "FR" } }, [
        _vm._v("EVENIR")
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          " peut être contactée par courrier à l’adresse indiquée ci-avant ou par email : "
        )
      ]),
      _c("span", { staticClass: "MsoHyperlink" }, [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("contact@evenir.energy")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("2."), _c("span")])
      ]),
      _c("b", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("FINALITES")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("Les Données Personnelles communiquées par l’Utilisateur à "),
        _c("span", [_vm._v("EVENIR")]),
        _vm._v(
          " font l’objet d’un traitement informatique et sont conservées et utilisées par "
        ),
        _c("span", { staticStyle: { color: "black" } }, [_vm._v("EVENIR")]),
        _vm._v(" pour les finalités suivantes :")
      ]),
      _c("span", { attrs: { lang: "FR" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "permettre l’accès à la Solution et son utilisation par l’Utilisateur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "vérifier et utiliser les données et éléments d’identification fournis et permettre leur utilisation via la Solution pour accéder aux informations sollicitées par l’Utilisateur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "partager et/ou renseigner les données d’identification et les demandes de l’Utilisateur avec pour assurer la prise de rendez-vous et la réalisation des prestations de charge aux dates et horaires convenus ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "assurer le fonctionnement de la Solution et de ses services, et notamment la facturation ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "améliorer les différents services proposés par la Solution et fournir à l’Utilisateur des informations pertinentes et à jour, répondre aux questions de l’Utilisateur ou à ses demandes en cas d’assistance ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "procéder à des statistiques sur la base d’informations ou de données non nominatives et utiliser ces statistiques pour améliorer la Solution et ses services ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "envoyer à l’Utilisateur des notifications et, avec son accord préalable exprès, des newsletters et des messages d’informations ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("·"), _c("span")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "envoyer à l’Utilisateur des notifications afin de vérifier la sécurité des accès à la Solution et aux informations ou données transmises à la requête de l’Utilisateur ou à son profit."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Lorsque l’Utilisateur utilise le Site, des données de navigation "
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("et de géolocalisation")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          " sont susceptibles d’être collectées et traitées par EVENIR ou par tout prestataire (tiers) de son choix à des fins statistiques et/ou pour améliorer la présentation, l’ergonomie et l’utilisation du Site, et en particulier mesurer et améliorer le nombre de pages vues, le nombre de visites et le parcours de l’Utilisateur."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("3."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("FONDEMENTS JURIDIQUES DES TRAITEMENTS")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les traitements réalisés par EVENIR conformément aux finalités énoncées ci-avant sont nécessaires pour permettre à EVENIR de :"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("●"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "remplir ses obligations contractuelles vis-à-vis des Utilisateurs ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("●"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("respecter les obligations légales qui lui incombent ;")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("●"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "répondre à certains intérêts légitimes relatifs notamment à la gestion de ses activités commerciales."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Dans tous les autres cas, les traitements réalisés par EVENIR sont possibles dans la mesure où l’Utilisateur y a expressément consenti par une case à cocher ("
        ),
        _c("i", [_vm._v("opt-in")]),
        _vm._v(").")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("4."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("SECURITE DES DONNEES PERSONNELLES")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR s’engage à prendre toutes les mesures techniques et organisationnelles pour assurer la protection et la sécurité des Données Personnelles, en particulier contre toute perte, altération, diffusion ou usage illégal."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR s’assure que de telles mesures sont mises en place sur l’ensemble des opérations réalisées dans le cadre de ses traitements, et notamment lors de la collecte des Données Personnelles, de leur stockage et de leur hébergement."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "A cet égard, EVENIR s’assure également que les tiers auxquels elle est susceptible de faire appel (prestataires techniques, fournisseurs, sous-traitants, partenaires…) respectent cette exigence de protection des Données Personnelles de l’Utilisateur par la mise en place des mesures appropriées, conformément au RGPD. Les mesures techniques et organisationnelles mises en place peuvent inclure le recours à des formulaires d’inscription sécurisés, le cryptage et/ou l’accès restreint aux Données Personnelles."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR invite l’Utilisateur à la plus grande prudence dans la communication, par ses soins à des tiers, de ses Données Personnelles mais également de ses identifiants et mots de passe personnels permettant l’accès à EVENIR. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Certains messages ou sollicitations reçus peuvent provenir de personnes mal intentionnées cherchant à obtenir des informations personnelles concernant l’Utilisateur en vue d’une utilisation frauduleuse (pratique du "
        ),
        _c("i", [_vm._v("phishing")]),
        _vm._v(
          "). Si l’Utilisateur reçoit un message qui paraît être une tentative de "
        ),
        _c("i", [_vm._v("phishing")]),
        _vm._v(
          ", il est invité à ne pas y répondre et à ne pas ouvrir les pièces jointes, les images ou les liens contenus dans le message. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("5."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("DESTINATAIRES DES DONNEES PERSONNELLES")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les Données Personnelles de l’Utilisateur sont destinées à la société EVENIR"
        ),
        _c("span"),
        _vm._v(
          "; elles pourront toutefois être communiquées par cette dernière à des tiers sous-traitants pour assurer l’accès et l’utilisation du Site et, avec l’accord de l’Utilisateur ou à sa demande, de tous services ou offres partenaires de EVENIR. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les Données Personnelles de l’Utilisateur ne font pas l’objet de communication ou de transfert à des tiers à des fins commerciales ou promotionnelles, sauf accord préalable exprès de l’Utilisateur."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le choix par l’Utilisateur de recevoir des offres ou messages de prospection commerciale de la part de EVENIR et/ou de ses partenaires est subordonné à son accord préalable et exprès au moyen d’une case à cocher correspondante accessible lorsqu’il est invité à fournir des Données Personnelles (par exemple lors de l’inscription). Si l’Utilisateur ne souhaite plus les recevoir, il dispose de la possibilité de se désinscrire à tout moment via le lien situé au bas de chacun des messages reçus ou en contactant EVENIR par email : "
        )
      ]),
      _c("span", { staticClass: "MsoHyperlink" }, [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("contact@evenir.energy")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Dans certains cas, EVENIR est susceptible de communiquer les Données Personnelles de l’Utilisateur à toute autorité administrative ou judiciaire qui lui en ferait la demande, conformément à la législation en vigueur."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("6."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v(
            "TRANSFERT DE DONNEES PERSONNELLES EN DEHORS DE L’UNION EUROPEENNE"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Dans le cadre de ses activités, EVENIR ne procède pas au transfert de Données Personnelles en dehors de l’Union Européenne."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’utilisation de certains outils est toutefois susceptible de permettre un tel transfert, par exemple l’utilisation de Google Analytics ou de messageries pour la diffusion d’informations ; dans une telle hypothèse, EVENIR s’engage à vérifier que l’éditeur dudit service, s’il est situé hors Union Européenne, respecte l’ensemble des principes et obligations fixés par le RGPD."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("7."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("DUREE DE CONSERVATION DES DONNEES PERSONNELLES")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR ne conservera les Données Personnelles de l’Utilisateur que pour une durée limitée, déterminée en fonction de la nature des données en cause, et du traitement concerné."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les Données Personnelles fournies par l’Utilisateur au moment de son inscription et dans le cadre de l’utilisation de EVENIR sont conservées aussi longtemps que l’Utilisateur utilise EVENIR. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Au-delà, les Données Personnelles seront archivées pour une durée de 2 (deux) années."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur peut à tout moment modifier son profil pour supprimer certaines données (à l’exception de son adresse email, obligatoire pour être identifié et pouvoir utiliser les services liés au Site). "
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les informations seront alors définitivement supprimées de la base de données active et mise en archivage sécurisé pour permettre à EVENIR de répondre à ses obligations légales (comptabilité, réquisition judiciaire…) pour une durée de 2 (deux) années."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les comptes inactifs des Utilisateurs ainsi que l’ensemble des Données personnelles s’y rapportant seront archivés dans un délai de 18 mois à compter du jour de dernière connexion de l’Utilisateur puis les données archivées seront supprimées à défaut de nouvelle connexion passé un délai de 18 mois."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("8."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("DROITS DE LA PERSONNE CONCERNEE")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Conformément à la loi « Informatique et Libertés » et au « RGPD », l’Utilisateur dispose d’un droit d’information, d’accès, d’opposition, de rectification, d’effacement et de suppression de tout ou partie des Données personnelles le concernant, et d’un droit à la limitation du traitement et à la portabilité des Données personnelles."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur peut, à tout moment, accéder à son compte pour modifier ou supprimer les informations et données le concernant. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("Il peut également exercer ses droits en en écrivant à l")
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("’a"),
        _c("span", { staticStyle: { color: "black" } }, [
          _vm._v("dresse postale")
        ]),
        _vm._v(" ci-dessus ou par email : "),
        _c("a", { attrs: { href: "mailto:contact@evenir.energy" } }, [
          _vm._v("contact@evenir.energy")
        ])
      ]),
      _c("span", { attrs: { lang: "FR" } }),
      _c("span", { attrs: { lang: "FR" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "De telles demandes devront être présentées par l’Utilisateur personnellement et par écrit, et, à la demande de EVENIR ou de son DPO, signées et accompagnées de la photocopie d’un titre d’identité portant la signature de son titulaire. Ces informations ne seront communiquées qu’à des fins d’identification de l’Utilisateur et ne seront pas conservées pour une durée excédant celle nécessaire au traitement de la réponse. La demande précisera l’adresse email ou postale à laquelle devra parvenir l’éventuelle réponse. EVENIR disposera d’un délai de 1 (un) mois pour répondre suivant réception de la demande."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur dispose également du droit de définir des directives générales relatives à la conservation, à l’effacement et à la communication de ses Données personnelles après son décès, qui peuvent être enregistrées auprès d’un tiers de confiance numérique certifié par la CNIL (www.cnil.fr), ainsi que des directives particulières relatives à la Solution et pouvant être enregistrées auprès de EVENIR, à l’adresse susmentionnée."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Utilisateur dispose par ailleurs du droit de formuler une réclamation auprès des autorités, et en particulier de la CNIL ("
        )
      ]),
      _c("span", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("www.cnil.fr")])
      ]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v(").")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR informe l’Utilisateur que, conformément à la législation en vigueur, les données fournies par ce dernier pourront être communiquées à toute autorité publique ou judiciaire qui lui en ferait la demande."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("9."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("UTILISATION DE COOKIES (SITE INTERNET)")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Lorsque l’Utilisateur utilise le site internet www.evenir.energy, des cookies ou autres traceurs sont susceptibles d’être installés sur son matériel (ordinateur, tablette ou téléphone mobile)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("i", [
          _c("span", { attrs: { lang: "FR" } }, [_vm._v("9.1"), _c("span")])
        ])
      ]),
      _c("b", [
        _c("i", [
          _c("u", [
            _c("span", { attrs: { lang: "FR" } }, [
              _vm._v("Que désigne-t-on par “cookies” et “autres traceurs”")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les cookies sont de petits fichiers qui contiennent des données qui sont générées lorsque l’Utilisateur navigue sur un site internet et sont installés sur son navigateur internet et/ou application mobile."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les autres technologies de traçage, tels que les traceurs, pixels et gifs invisibles, fonctionnent de la même façon que les cookies."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les cookies et autres traceurs sont utilisés pour suivre l’activité de l’Utilisateur et permettre à "
        ),
        _c("span", { staticStyle: { color: "black" } }, [_vm._v("EVENIR")]),
        _vm._v(
          " (en tant que premier destinataire des cookies) ainsi que des tiers (pour les cookies tiers) de collecter des informations sur la manière dont l’Utilisateur utilise le Site et ses fonctionnalités et améliorer ainsi leur fonctionnement et l’expérience de l’Utilisateur."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Il existe deux types de cookies : les cookies de session et les cookies persistants."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("●"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les cookies de session sont temporaires et sont par conséquent automatiquement effacés de l’appareil de l’Utilisateur à chaque fois que celui-ci ferme son navigateur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("●"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les cookies persistants restent sur l’appareil de l’Utilisateur pour une durée déterminée dans les cookies et sont activés à chaque fois que l’Utilisateur visite le site depuis lequel ils ont été installés."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("i", [
          _c("span", { attrs: { lang: "FR" } }, [_vm._v("9.2"), _c("span")])
        ])
      ]),
      _c("b", [
        _c("i", [
          _c("u", [
            _c("span", { attrs: { lang: "FR" } }, [
              _vm._v("Types de cookies utilisés par "),
              _c("span", { staticStyle: { color: "black" } }, [
                _vm._v("EVENIR")
              ]),
              _vm._v(" et finalités")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          " utilise deux sortes de cookies : les cookies strictement nécessaires, pour lesquels le consentement de l’Utilisateur n’est pas requis."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Si l’Utilisateur ne donne pas son consentement à l’installation des cookies, ceux-ci ne seront pas installés sur son navigateur. Dans ce cas, certaines des fonctionnalités du Site pourront ne pas être accessibles, et son expérience de navigation amoindrie."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("●"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("i", [
        _c("u", [
          _c("span", { attrs: { lang: "FR" } }, [
            _vm._v("Les cookies strictement nécessaires")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Ces cookies sont nécessaires pour les opérations propres aux services proposés sur EVENIR."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Ils sont utilisés pour fournir à l’Utilisateur les fonctionnalités basiques, telles que la possibilité de créer un compte personnel ou encore se souvenir des informations préalablement fournies."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("En particulier, ")]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(" utilise les cookies suivants :")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("-"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Des cookies d’authentification liés au fournisseur d’identité (Microsoft Azure Active Directory B2C), afin de permettre la persistance de l’identité de l’utilisateur. Ces cookies (préfixés par « x-ms » sont des cookie de session, ils sont supprimé dès que l’utilisateur ferme son navigateur;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("-"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Des cookies d’authentification liés aux données d’identifications sur l’app Evenir, afin de permettre la persistance de l’identité de l’utilisateur. Ces cookies (préfixés par « msal. » sont des cookie de session, ils sont supprimé dès que l’utilisateur ferme son navigateur;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Si l’Utilisateur empêche l’installation de ces cookies, il ne pourra plus utiliser ces fonctionnalités et le Site pourra ne pas fonctionner de façon efficace."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("●"), _c("span")]),
      _c("i", [
        _c("u", [
          _c("span", { attrs: { lang: "FR" } }, [
            _vm._v("Les cookies de performance")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Ces cookies sont utilisés pour collecter des données anonymes à des fins statistiques."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Ils permettent de mesurer l’audience d’un site et d’analyser la façon dont les Utilisateurs naviguent (nombre de visiteurs sur le site, nombre de visites par page, temps passé sur chaque page, localisation des clics, mesures d’efficacité des publicités…)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Ils peuvent également être utilisés pour détecter des problèmes de navigation et toute autre difficulté."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("En particulier, ")]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("EVENIR")]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(" utilise les cookies suivants :")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("-"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Un cookie propre à l’utilisateur, nommé « ai_user », afin de persister l’identifiant de l’utilisateur courant. Ce cookie a une durée de vie de 365 jours à compter de son installation sur le navigateur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("-"),
        _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } })
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Un cookie propre à la session, nommé « ai_session », afin de persister l’identifiant de la session de navigation. Ce cookie a une durée de vie de 30 minute à compter de son installation sur le navigateur ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("Ces cookies aident à améliorer la navigation.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("10."), _c("span")])
      ]),
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITE")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "La présente Politique de confidentialité peut être modifiée par "
        ),
        _c("span", [_vm._v("EVENIR")]),
        _vm._v(" à tout moment.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "En cas de modification de la Politique de confidentialité, la nouvelle version en vigueur sera applicable à l’Utilisateur à compter de sa connexion à EVENIR."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("En cas de litige entre l’Utilisateur et "),
        _c("span", [_vm._v("EVENIR")]),
        _vm._v(
          ", la Politique de confidentialité à prendre en compte sera celle en vigueur au moment des faits à l’origine du litige."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _vm._m(5),
      _vm._m(6),
      _vm._m(7),
      _vm._m(8),
      _vm._m(9),
      _vm._m(10),
      _vm._m(11),
      _vm._m(12),
      _vm._m(13),
      _vm._m(14),
      _vm._m(15),
      _vm._m(16),
      _vm._m(17),
      _vm._m(18),
      _vm._m(19),
      _vm._m(20),
      _vm._m(21),
      _vm._m(22),
      _vm._m(23),
      _vm._m(24),
      _vm._m(25),
      _vm._m(26),
      _vm._m(27),
      _vm._m(28),
      _vm._m(29),
      _vm._m(30),
      _vm._m(31),
      _vm._m(32),
      _vm._m(33),
      _vm._m(34),
      _vm._m(35),
      _vm._m(36),
      _vm._m(37),
      _vm._m(38),
      _vm._m(39),
      _vm._m(40),
      _vm._m(41),
      _vm._m(42),
      _vm._m(43),
      _vm._m(44),
      _vm._m(45),
      _vm._m(46),
      _vm._m(47),
      _vm._m(48),
      _vm._m(49),
      _vm._m(50),
      _vm._m(51),
      _vm._m(52),
      _vm._m(53),
      _vm._m(54),
      _vm._m(55),
      _vm._m(56),
      _vm._m(57),
      _vm._m(58),
      _vm._m(59),
      _vm._m(60),
      _vm._m(61),
      _vm._m(62),
      _vm._m(63),
      _vm._m(64),
      _vm._m(65),
      _vm._m(66),
      _vm._m(67),
      _vm._m(68),
      _vm._m(69),
      _vm._m(70),
      _vm._m(71),
      _vm._m(72),
      _vm._m(73),
      _vm._m(74),
      _vm._m(75),
      _vm._m(76),
      _vm._m(77),
      _vm._m(78),
      _vm._m(79),
      _vm._m(80),
      _vm._m(81),
      _vm._m(82),
      _vm._m(83),
      _vm._m(84),
      _vm._m(85),
      _vm._m(86),
      _vm._m(87),
      _vm._m(88),
      _vm._m(89),
      _vm._m(90),
      _vm._m(91),
      _vm._m(92),
      _vm._m(93),
      _vm._m(94),
      _vm._m(95),
      _vm._m(96),
      _vm._m(97),
      _vm._m(98),
      _vm._m(99),
      _vm._m(100),
      _vm._m(101),
      _vm._m(102),
      _vm._m(103),
      _vm._m(104),
      _vm._m(105),
      _vm._m(106),
      _vm._m(107),
      _vm._m(108),
      _vm._m(109),
      _vm._m(110),
      _vm._m(111),
      _vm._m(112),
      _vm._m(113),
      _vm._m(114),
      _vm._m(115),
      _vm._m(116),
      _vm._m(117),
      _vm._m(118),
      _vm._m(119),
      _vm._m(120),
      _vm._m(121),
      _vm._m(122),
      _vm._m(123),
      _vm._m(124),
      _vm._m(125),
      _vm._m(126),
      _vm._m(127),
      _vm._m(128),
      _vm._m(129),
      _vm._m(130),
      _vm._m(131),
      _vm._m(132),
      _vm._m(133),
      _vm._m(134),
      _vm._m(135),
      _vm._m(136),
      _vm._m(137),
      _vm._m(138),
      _vm._m(139),
      _vm._m(140),
      _vm._m(141),
      _vm._m(142),
      _vm._m(143),
      _vm._m(144),
      _vm._m(145),
      _vm._m(146),
      _vm._m(147),
      _vm._m(148),
      _vm._m(149),
      _vm._m(150),
      _vm._m(151),
      _vm._m(152),
      _vm._m(153),
      _vm._m(154),
      _vm._m(155),
      _vm._m(156),
      _vm._m(157),
      _vm._m(158),
      _vm._m(159),
      _vm._m(160),
      _vm._m(161),
      _vm._m(162),
      _vm._m(163),
      _vm._m(164),
      _vm._m(165),
      _vm._m(166),
      _vm._m(167),
      _vm._m(168),
      _vm._m(169),
      _vm._m(170),
      _vm._m(171),
      _vm._m(172),
      _vm._m(173)
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }