var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(64)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { attrs: { align: "center" } }, [
      _c("b", [
        _c("u", [
          _c("span", { attrs: { lang: "FR" } }, [_vm._v("Mentions légales")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_c("span", { attrs: { lang: "FR" } }, [_vm._v("Editeur :")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("L’application EVENIR est la propriété de la société ")
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR, SAS au capital de 30.000 euros immatriculée au Registre du Commerce et des Sociétés de Paris sous le n°830 800 488 et dont le siège social est situé 86, rue de l’Assomption - 75016 Paris, France. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Directeur de publication : ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("Monsieur Damien Grouselle")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Création, conception et développement : ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("L’application EVENIR est une ")
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "plateforme de mise en relation entre des conducteurs de véhicules électriques qui souhaitent recharger leur véhicule et des hôtes qui mettent à disposition leur point de recharge. Cette plateforme est conçue et rendue accessible par EVENIR via une application mobile "
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("ou consultée sur le site internet d’EVENIR.")
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          " L’ensemble des fonctionnalités qui y attachées, dont les modalités sont décrites sur l’application et "
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v("sur le site internet d’EVENIR")
      ]),
      _c("span", { attrs: { lang: "FR" } }, [_vm._v(".")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("Hébergement : ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { name: "_Hlk33784302" } }, [
        _c("b", [_c("span", { attrs: { lang: "FR" } })])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [_vm._v("Microsoft Azure")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("Responsabilité :")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR décline toute responsabilité quant à l’usage qui peut être fait des informations diffusées sur l’Application. Le contenu de l’Application peut comporter des inexactitudes techniques, erreurs typographiques ou omissions. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR ne peut, en aucun cas, être tenue responsable des dommages pouvant en résulter et de l’utilisation et de l’interprétation des informations disponibles sur l’Application. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "De manière générale, EVENIR décline toute responsabilité résultant d’un éventuel dommage survenant, notamment, en raison de la consultation et de l’usage de l’Application."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Si une information vous semble inexacte ou susceptible d’être mal interprétée, veuillez nous contacter par e-mail : "
        ),
        _c("a", { attrs: { name: "_Hlk33784376" } }, [
          _c("span", { staticClass: "MsoHyperlink" }, [
            _vm._v("contact@evenir.energy")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("Droit d’auteur :")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’Application constitue une œuvre protégée par la législation en vigueur, et notamment les articles L. 122-1 et suivants du Code de la propriété intellectuelle, exploitée par la société EVENIR. Les textes, photographies, images animées ou non, savoir-faire, dessins, graphismes et tout autre élément composant le Site sont la propriété de EVENIR ou de tiers ayant autorisé EVENIR à les utiliser. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Toute reproduction intégrale ou partielle de l’Application est interdite, sauf accord préalable exprès et écrit de EVENIR."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Toute utilisation contraire de tout ou partie de l’Application ou de l’un ou quelconque de ses éléments constituerait une contrefaçon telle que définie aux articles L. 335- 2 et suivants du code de la propriété intellectuelle, et est susceptible d’entraîner des poursuites civiles et/ou pénales. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR se réserve le droit de refuser l’utilisation de sa marque ou de son logo par un tiers, sans son accord préalable exprès. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Toute reproduction autorisée par EVENIR (dans un but personnel, associatif ou professionnel) implique l’indication de la source (EVENIR)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("Liens internet :")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les liens depuis l’Application vers des sites internet ne sont proposés qu’à titre informatif. Leur contenu et leur accessibilité ne sont pas garantis par EVENIR. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [
          _vm._v("Données à caractère personnel :")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR est le responsable du traitement des données personnelles réalisé via le Site et, en cette qualité, s’engage à respecter la loi n°78-17 du 6 janvier 1978 (dite loi « Informatique et Libertés ») modifiée et le Règlement Général sur la Protection des Données (dit « RGPD »)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Les données personnelles communiquées par l’utilisateur de l’Application à EVENIR (et notamment son adresse IP) font l’objet d’un traitement informatique et sont conservées et utilisées par EVENIR pour : permettre l’accès à l’Application et son utilisation ; connaître son parcours sur le Site et ses préférences ; améliorer les différents services proposés par EVENIR et fournir des informations pertinentes et à jour ; procéder à des statistiques sur la base d’informations ou de données non nominatives et utiliser ces statistiques pour améliorer le service EVENIR ; adresser à l’utilisateur, avec son accord préalable exprès, des newsletters et des messages d’informations ; le cas échéant, si l’utilisateur procède à une demande ou commande sur le Site, traiter sa demande ou commande et lui fournir les produits et/ou services commandés."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Conformément à la loi « Informatique et Libertés » et au RGPD, l’utilisateur dispose d’un droit d’information, d’accès, d’opposition, de rectification, d’effacement et de suppression de tout ou partie des données personnelles le concernant, et d’un droit à la limitation du traitement et à la portabilité des données personnelles, qu’il peut exercer en écrivant à : EVENIR 86, rue de l’Assomption 75016 Paris ou par e-mail à : "
        ),
        _c("a", { attrs: { href: "mailto:contact@evenir.energy" } }, [
          _vm._v("contact@evenir.energy")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "EVENIR a désigné un Délégué à la Protection des Données (« DPO »), lequel peut être contacté en écrivant à : "
        )
      ]),
      _c("span", { attrs: { lang: "FR" } }, [
        _c("a", { attrs: { href: "mailto:contact@evenir.energy" } }, [
          _vm._v("contact@evenir.energy")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "L’utilisateur dispose par ailleurs du droit de formuler une réclamation auprès des autorités, et en particulier de la CNIL ("
        ),
        _c("a", { attrs: { href: "http://www.cnil.fr" } }, [
          _vm._v("www.cnil.fr")
        ]),
        _vm._v(").")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Pour en savoir plus sur la politique et les engagements d’EVENIR en matière de protection des données à caractère personnel, vous pouvez consulter la "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://app.evenir.energy/public/docs/confidentialite.pdf"
            }
          },
          [_vm._v("Politique de confidentialité")]
        ),
        _vm._v(".")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_c("span", { attrs: { lang: "FR" } })])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("span", { attrs: { lang: "FR" } }, [_vm._v("Loi applicable :")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { lang: "FR" } }, [
        _vm._v(
          "Le contenu du Site et son utilisation sont régis par la loi française. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { attrs: { lang: "FR" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _vm._m(5),
      _vm._m(6),
      _vm._m(7),
      _vm._m(8),
      _vm._m(9),
      _vm._m(10),
      _vm._m(11),
      _vm._m(12),
      _vm._m(13),
      _vm._m(14),
      _vm._m(15),
      _vm._m(16),
      _vm._m(17),
      _vm._m(18),
      _vm._m(19),
      _vm._m(20),
      _vm._m(21),
      _vm._m(22),
      _vm._m(23),
      _vm._m(24),
      _vm._m(25),
      _vm._m(26),
      _vm._m(27),
      _vm._m(28),
      _vm._m(29),
      _vm._m(30),
      _vm._m(31),
      _vm._m(32),
      _vm._m(33),
      _vm._m(34),
      _vm._m(35),
      _vm._m(36),
      _vm._m(37),
      _vm._m(38),
      _vm._m(39),
      _vm._m(40),
      _vm._m(41),
      _vm._m(42),
      _vm._m(43),
      _vm._m(44),
      _vm._m(45),
      _vm._m(46),
      _vm._m(47),
      _vm._m(48),
      _vm._m(49),
      _vm._m(50),
      _vm._m(51),
      _vm._m(52),
      _vm._m(53),
      _vm._m(54),
      _vm._m(55),
      _vm._m(56),
      _vm._m(57),
      _vm._m(58),
      _vm._m(59),
      _vm._m(60),
      _vm._m(61),
      _vm._m(62),
      _vm._m(63)
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }